import React from 'react';
import {Button, Card, Collapse, Progress} from 'antd';
import themeCardStyle from '../../less/themecards.module.less';
import PropTypes from 'prop-types';
import NumberFormat from "react-number-format";
import ArrowRightOutlined from "@ant-design/icons/lib/icons/ArrowRightOutlined";
import {Link, useIntl} from 'gatsby-plugin-intl';
import RightOutlined from '@ant-design/icons/lib/icons/RightOutlined';

const {Panel} = Collapse;
const ThemeCard = ({
                     total,
                     offset,
                     amount,
                     isMobile,
                     title,
                     description,
                     color,
                     showcases,
                     info,
                     projectsTranslation,
                     onClick,
                     hasChildren,
                     hasReadMore,
                     showChildrenTranslation,
                     readMoreTranslation,
                     highlighted,
                     onMouseEnter,
                     onMouseLeave,
                     onHeightChange
                   }) => {
  const intl = useIntl();
  const amountPercentage = Math.round(((amount / total) * 100) * 100) / 100; // limit to 2 decimal points
  const offsetPercentage = Math.round(((offset / total) * 100) * 100) / 100; // limit to 2 decimal points

  return (
    <Card className={highlighted ? themeCardStyle.highlighted : ''}
          onMouseEnter={() => onMouseEnter()}
          onMouseLeave={() => onMouseLeave()}>
      <div className={themeCardStyle.header}>
        <h4 className={themeCardStyle.title}>{title}</h4>
        {!isMobile ? (
          <b>{amountPercentage}%</b>
        ) : ''}
      </div>
      <div className={themeCardStyle.content}>
        <span className={themeCardStyle.value}>
        <NumberFormat value={amount}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      prefix={'€ '}/>
          {isMobile ? (
            <span
              className={themeCardStyle.percentage}> <b> ({amountPercentage}%)</b></span>
          ) : ''}
        </span>

        <div className={themeCardStyle.graph}>
          <Progress percent={offsetPercentage + amountPercentage}
                    successPercent={offsetPercentage}
                    strokeColor={color}
                    showInfo={false}/>
        </div>

      </div>
      {info ? (
        <p style={{margin: 0, fontStyle: 'italic'}}>{info}</p>
      ) : ''}

      <div className={themeCardStyle.controls}>
        {showcases.length || hasReadMore ? (
          <Collapse defaultActiveKey={['0']}
                    expandIcon={({ isActive }) => <RightOutlined role={''} aria-label={''} rotate={isActive ? 90 : 0} />}
                    onChange={() => setTimeout(() => onHeightChange(), 250)}
                    className={themeCardStyle.collapse}>
            {showcases.length ? (
              <Panel header={projectsTranslation} key="2">
                <ul style={{listStyle: 'none', padding: '0', margin: '0'}}>
                  {
                    showcases.map((showcase) => {
                      return (
                        <li key={showcase.id}>
                          <Link to={`/showcase/${showcase.id}/`}
                                className={'ant-btn ant-btn-link'}>
                            <div style={{textAlign: 'initial'}}>
                              <span>{intl.translate(showcase, 'title')} </span>
                              <ArrowRightOutlined role='' aria-label=''
                                                  style={{marginLeft: '6px'}}/>
                            </div>
                          </Link>
                        </li>
                      );
                    })
                  }
                </ul>
              </Panel>
            ) : ''}

            {hasReadMore ? (
              <Panel header={readMoreTranslation} key="3">
                <p style={{whiteSpace: 'pre-wrap'}}>{description}</p>
              </Panel>
            ) : ''}
          </Collapse>
        ) : ''}

        <div className={themeCardStyle.buttons}>
          {hasChildren ? (
            <Button onClick={onClick}>{showChildrenTranslation}</Button>
          ) : ''}
        </div>
      </div>
    </Card>
  );
};

ThemeCard.propTypes = {
  total: PropTypes.number,
  offset: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  amount: PropTypes.number,
  color: PropTypes.string,
  showcases: PropTypes.array,
  projectsTranslation: PropTypes.string,
  showChildrenTranslation: PropTypes.string,
  hasChildren: PropTypes.bool,
  hasReadMore: PropTypes.bool,
  highlighted: PropTypes.bool,
  onHover: PropTypes.func,
};

ThemeCard.defaultProps = {
  title: `Economische zaken`,
  description: '',
  total: 10000000,
  amount: 1000000,
  offset: 0,
  color: 'blue',
  showcases: [],
  projectsTranslation: ``,
  showChildrenTranslation: ``,
  hasChildren: false,
  hasReadMore: false,
  highlighted: false,
};

export default ThemeCard;
