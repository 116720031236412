import * as React from 'react';
import {Button, Card} from 'antd';
import NewsCardStyle from '../../less/news-card.module.less';
import PropTypes from 'prop-types';
import useIntl from '../../helpers/useIntl';
import Img from 'gatsby-image';
import ArrowRightOutlined from '@ant-design/icons/lib/icons/ArrowRightOutlined';
import {Link} from "gatsby-plugin-intl";
import {navigate} from "@reach/router"

const NewsCard = ({title, description, link, thumbnail, headingLevel = 4}) => {
  const intl = useIntl();
  let thumbnailSources;

  if (thumbnail && thumbnail.mobile && thumbnail.desktop) {
    thumbnailSources = [
      thumbnail.mobile.fluid,
      {
        ...thumbnail.desktop.fluid,
        media: `(min-width: 750px)`,
      },
    ];
  }

  const CustomHeading = `h${headingLevel}`

  return (
    <Card
      hoverable
      onClick={() => navigate(`/${intl.locale}/news/${link}/`)}
      className={NewsCardStyle.news}
      cover={
        thumbnailSources ? (
          <Img fluid={thumbnailSources} alt=''/>
        ) : (
          ''
        )
      }
    >
      <CustomHeading className='ant-card-meta-title'>
        <Link to={`/news/${link}/`}>
          {title}
        </Link>
      </CustomHeading>
      <div className={NewsCardStyle.description}
           dangerouslySetInnerHTML={{__html: description}}/>
      <div className={NewsCardStyle.cardFooter}>
        <Button type={'link'} aria-hidden={true} tabIndex={-1}>
          {intl.formatMessage({id: 'general_readmore'})}
          <ArrowRightOutlined role='' aria-label=''/>
        </Button>
      </div>
    </Card>
  );
};

NewsCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  thumbnail: PropTypes.object,
};

NewsCard.defaultProps = {
  title: '',
  description: '',
  link: '',
  thumbnail: {},
};

export default NewsCard;
